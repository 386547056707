import firebase from "firebase/app";
import "firebase/firestore"
import "firebase/auth";

  const firebaseConfig = {
    apiKey: "AIzaSyDbRhTmYyRmP_5gr7DVTV7DggD37GhW-hE",
    authDomain: "lostit-94b68.firebaseapp.com",
    projectId: "lostit-94b68",
    storageBucket: "lostit-94b68.appspot.com",
    messagingSenderId: "750598667781",
    appId: "1:750598667781:web:df17ebf9de0bddf4e6a66c",
    measurementId: "G-6PWM4TWEV7"
  };

  firebase.initializeApp(firebaseConfig);

//   const storage = firebase.storage();

  export default firebase;
//   firebase.analytics();