import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
    color: props => props.color,
  },

  contentWrapper: {
    display: "flex",
    justifyContent: "center"
  },

  returnButton: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px"
  },

  informationBox: {
    width: "100%",
    padding: "24px",
    marginTop: "24px",
    backgroundColor: "#ffffff",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px"
  },

  informationBoxTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
    marginBottom: "24px",
    flexDirection: "column"
  },

  informationBoxForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  informationBoxInput: {
    width: 320,
    marginBottom: "16px"
  },

  informationBoxButton: {
    backgroundColor: "#00BD08",
    marginBottom: "16px",
    '&:hover': {
      backgroundColor: "#00BD08"
    }
  },

  informatonSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },

  deleteSection: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
  },

  deleteButton:{
    '&:hover': {
      color: 'white',
      backgroundColor: "red"
    }
  },
  
  boxHeader: {
    display: "flex"
  },


});

export { useStyles };