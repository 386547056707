import React , { useContext }from "react";
import { Box, Typography } from '@material-ui/core';
import { withRouter } from "react-router";
import { useStyles } from './styles.js';

const NotFound = () => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.mainSection}>
                <Typography variant="h5">404 - Página não encontrada</Typography>
                <Typography>Esta pagina não se encontra disponível</Typography>
            </Box>
            <Box className={classes.lowerSection}>
            </Box>
        </>
    )
}

export default withRouter(NotFound);
