import React, { useState, useContext } from 'react'
import { Container, TextField, Button, Box, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Link } from "react-router-dom";
import { AuthContext } from "../../providers/Auth.js";
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './styles.js'

const NewItem = ({ history }) => {
    const classes = useStyles();
    const { userRef } = useContext(AuthContext);
    const [itemName, setItemName] = useState();
    const [itemDescription, setItemDescription] = useState();
    const [btnDisabled, setButtonDisabled] = useState(false);

    const uploadItem = event => {
        setButtonDisabled(true);
        event.preventDefault();
        userRef.collection("items").add({
            name: itemName,
            description: itemDescription ? itemDescription : ""
        })
            .then(() => {
                history.push('/');
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
    }

    const itemNameHandleChange = (event) => {
        setItemName(event.target.value);
    };
    const itemDescriptionHandleChange = (event) => {
        setItemDescription(event.target.value);
    };

    return (
        <>
            <Container fixed className={classes.contentWrapper}>
                <Box className={classes.informationBox}>
                    <Box className={classes.returnButton}>
                        <Link to="/">
                            <IconButton color="primary" component="span">
                                <ArrowBack />
                            </IconButton>
                        </Link>
                        <Typography>Voltar</Typography>
                    </Box>
                    <Box className={classes.informationBoxTitle}>
                        <Typography variant="h5">Cadastrar item</Typography>
                    </Box>
                    <form className={classes.informationBoxForm} onSubmit={uploadItem}>
                        <TextField
                            onChange={itemNameHandleChange}
                            className={classes.informationBoxInput}
                            value={itemName}
                            label="Nome do item"
                            size="large"
                            variant="outlined"
                            color="primary"
                            required={true}
                            inputProps={{ maxLength: 30 }}
                        />
                        <TextField
                            onChange={itemDescriptionHandleChange}
                            className={classes.informationBoxInput}
                            label="Descrição"
                            multiline={true}
                            variant="outlined"
                            rows={3}
                            value={itemDescription}
                        />
                        <Button disabled={btnDisabled} type="submit" variant="contained" color="primary" className={classes.mainButton}>Cadastrar</Button>
                    </form>
                </Box>
            </Container>
        </>
    );
}

export default NewItem;