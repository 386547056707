import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./providers/Auth";
import Navbar from './components/Navbar/index';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <Route {...rest} render={routeProps => 
      !!currentUser
        ?<> <Navbar/><div style={{overflowY: "auto"}}><RouteComponent {...routeProps} /></div></>
        : (<Redirect to={"/"} />)
    }
    />
  );
};


export default PrivateRoute