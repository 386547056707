import React from 'react';
import { Box, Typography } from '@material-ui/core';
import QRcode from 'qrcode.react';
import { Link } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import companyLogo from '../../app_logo.png';
import { useStyles } from './styles.js'
import html2canvas from 'html2canvas';

const ItemCard = ({ name, user, itemId }) => {
    const classes = useStyles();
    const base_url = window.location.origin;

    const downloadQR = event => {
        event.preventDefault();
        event.stopPropagation();
        const elem = document.getElementById(`${itemId}`)
        elem.style.display = "block"
        html2canvas(elem)
            .then(canvas => {
                const pngUrl = document.body.appendChild(canvas)
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream");
                let downloadLink = document.createElement("a");
                downloadLink.href = pngUrl;
                downloadLink.download = `${name}.png`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
        elem.style.display = "none"
    }

    return (
        <>
            <Box className={classes.listElement}>
                <Typography>{name}</Typography>
                <Box>
                    <Link to={`edit-item/${user}/${itemId}`}>
                        <IconButton color="primary" component="span" className={classes.listElementIcon}>
                            <EditIcon />
                        </IconButton>
                    </Link>
                    <IconButton onClick={downloadQR} color="primary" component="span" className={classes.listElementIcon}>
                        <GetAppIcon />
                    </IconButton>
                </Box>
                <Box id={`${itemId}`} className={classes.sticker}>
                    <div className={classes.stickerDispostion}>
                        <QRcode
                            value={`${base_url}/item/${user.id}/${itemId}`}
                            size={100}
                            level={'Q'}
                            includeMargin={true}
                            imageSettings={{
                                src: companyLogo,
                                x: null,
                                y: null,
                                excavate: true,
                            }}
                        />
                        <Typography variant="caption" style={{fontSize: "8px"}}>Cadastrado em <strong>lostit.com.br</strong></Typography>
                    </div>
                </Box>
            </Box>
        </>

    );
};

export default ItemCard;