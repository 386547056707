import React, { useState, useContext, useEffect } from 'react'
import { Container, Typography, Box } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Link } from "react-router-dom";
import firebase from '../../firebase';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhoneIcon from '@material-ui/icons/Phone';
import { AuthContext } from "../../providers/Auth.js";
import { useStyles } from './styles.js'

function View() {
    const classes = useStyles();
    const pathArray = window.location.pathname.split('/');
    const userId = pathArray[2];
    const itemId = pathArray[3];
    const { currentUser } = useContext(AuthContext);
    const [ownerName, setOwnerName] = useState("");
    const [ownerPhone, setOwnerPhone] = useState("");
    const [itemName, setItemName] = useState("");
    const [loading, setLoading] = useState(true);
    const [itemDescription, setItemDescription] = useState("");
    const ownerRef = firebase.firestore().collection("users").doc(`${userId}`);
    const itemRef = ownerRef.collection('items').doc(`${itemId}`);

    console.log("View");

    const getOwnerInfo = () => {
        ownerRef.get().then((ownerDoc) => {
            if (ownerDoc.exists) {
                setOwnerName(ownerDoc.data().name);
                setOwnerPhone(ownerDoc.data().phone);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getItemInfo = () => {
        itemRef.get().then((itemDoc) => {
            if (itemDoc.exists) {
                setItemName(itemDoc.data().name);
                setItemDescription(itemDoc.data().description);
            } else {
                // doc.data() will be undefined in this case
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        setLoading(false);
    }

    useEffect(() => {
        getOwnerInfo();
        getItemInfo();
    }, [])

    return (
        <>
            {loading
                ? <Box className={classes.loadingWrapper}><CircularProgress /></Box>
                : <Container fixed className={classes.contentWrapper}>
                    <Box className={classes.informationBox}>
                        {(currentUser && currentUser.uid === userId) ?
                            <Box className={classes.boxHeader}>
                                <Link to="/" className={classes.backButton}>
                                    <IconButton color="primary" component="span">
                                        <ArrowBack />
                                    </IconButton>
                                </Link>
                                <Box className={classes.informationBoxTitle}>
                                    <Typography variant="h5">Dados do item</Typography>
                                    <Typography variant="caption">Como o item será visto por quem o encontrar </Typography>
                                </Box>
                            </Box> : null
                        }
                        <form className={classes.informationBoxForm}>
                            <Box className={classes.informationSection}>
                                <Typography variant="h5" className={classes.itemInformation} >{itemName}</Typography>
                                <Typography>de <span className={classes.ownerInformation}>{ownerName}</span></Typography>
                            </Box>
                            <Box className={classes.informationSection}>
                                <Typography variant="overline">Descrição</Typography>
                                <Box className={classes.textBox}>
                                    <Typography className={classes.itemInformation}>{itemDescription}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.phoneSection}>
                                <PhoneIcon className={classes.phoneSectionIcon} />
                                <Typography>{ownerPhone}</Typography>
                            </Box>
                        </form>
                    </Box>
                </Container>
            }
        </>
    )
}

export default View;