import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
    color: props => props.color,
  },
  contentWrapper: {
    justifyContent: "center"
  },

  loadingWrapper: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
  },

  backButton: {
    marginRight: "16px"
  },

  textBox: {
    wordWrap: "break-word"
  },

  informationBox: {
    // width: "320px",
    padding: "24px",
    marginTop: "24px",
    backgroundColor: "#ffffff",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px"
  },

  informationBoxTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBottom: "24px",
    flexDirection: "column"
  },

  informationBoxForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  informationBoxInput: {
    // width: 320,
    marginBottom: "16px"
  },

  informationBoxButton: {
    backgroundColor: "#00BD08",
    marginBottom: "16px"
  },

  informationSection:{
    marginBottom: '16px'
  },

  phoneSection:{
    display: "flex",
    alignItems: "center"
  },

  phoneSectionIcon:{
    marginRight: "8px"
  },

  itemInformation:{
    // width: "320px"
  },

  ownerInformation:{
    color: "#00BD08"
  },

  boxHeader: {
    display: "flex"
  },

});

export { useStyles };