import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
    color: props => props.color,
  },
  navBarStructure: {
    backgroundColor: "#ffffff !important",
    display: "flex !important",
    flexDirection: 'row !important',
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    color: "black !important",
    // boxShadow: 'none !important'
  },

  logo: {
    textDecoration: 'none',
    color: 'inherit'
  },

  profile: {
    display: "flex",
    flexDirection: "row"
  },

  logoText: {
    color: "#00BD08",
    fontWeight: "bold"
  },



});

export { useStyles };