import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './pages/Home/index';
import Profile from './pages/Profile/index';
import Login from './pages/Login/index';
import SignUp from './pages/SignUp/index';
import NotFound from './pages/NotFound/index';
import NewItem from './pages/NewItem/index';
import ViewItem from './pages/ViewItem/index';
import EditItem from './pages/Edit/index';
import { AuthContext } from "./providers/Auth.js";
import PrivateRoute from './PrivateRoute';

export const Logged = () => {
  const { currentUser, userRef } = useContext(AuthContext)
  // alert("Vim pro logado porque o currentUser é:" + !!currentUser + " e o userRef é:" + userRef)

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path='/profile' component={Profile} />
        <PrivateRoute exact path='/newItem' component={NewItem} />
        <PrivateRoute exact path='/edit-item/:userId/:itemId' component={EditItem} />
        <PrivateRoute exact path="/item/:userId/:itemId" component={ViewItem} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="*" component={NotFound} />
      </Switch>
    </Router>
  )
}

export const UnLogged = () => {
  const { currentUser, userRef } = useContext(AuthContext)
  // alert("Vim pro Não logado porque o currentUser é:" + !!currentUser + " e o userRef é:" + userRef)

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={SignUp} />
        <Route exact path="/signUp" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/item/:userId/:itemId" component={ViewItem} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  )
}

function Paths() {
  const { currentUser, userRef } = useContext(AuthContext)
  // alert("avaliando rota com currentUser:" + !!currentUser)

  return (
    <Router>
      <div style={{
        position: "fixed",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        overflow: "auto",
        height: "fill-available",
        backgroundColor: "#f6f8fa",
        display: "flex",
        flexDirection: "column"
      }}>
        {!!currentUser && !!userRef
          ? <Route path="/" render={() => <Logged />} />
          : <Route path="/" render={() => <UnLogged />} />
        }
      </ div>
    </Router>
  );
}

export default Paths;