import React from "react";
import './App.css';
import { AuthProvider } from './providers/Auth';
import Paths from './Paths'

function App() {
  return (
    <AuthProvider>
      <Paths />
    </AuthProvider>
  );
}

export default App;