import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
    color: props => props.color,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "104px"
  },

  loadingWrapper: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
  },

  headerSection:{
    marginBottom: "16px"
  },

  removeLinkStyle:{
    textDecoration: 'none',
    color: 'inherit'
  },

  mainButton:{
    backgroundColor: "#00BD08",
    '&:hover': {
      backgroundColor: "#00BD08"
    }
  },
});

export { useStyles };