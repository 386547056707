import React, { useContext, useState } from 'react';
import { Container, Button, Box, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import { AuthContext } from "../../providers/Auth.js";
import { useStyles } from './styles.js'
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Profile = ({ history }) => {
    const classes = useStyles();
    const { currentUser, userRef, username, phoneNumber, updateName, updatePhone } = useContext(AuthContext);
    const [openChangeUsername, setOpenChangeUsername] = useState(false);
    const [openChangePhoneNumber, setOpenChangePhoneNumber] = useState(false);
    const [openDeleteUser, setOpenDeleteUser] = useState(false);
    const [newUsername, setNewUsername] = useState(username);
    const [newPhoneNumber, setNewPhoneNumber] = useState(phoneNumber);

    console.log("Profile");

    const handleClickOpenChangeUsername = () => {
        setOpenChangeUsername(true);
    };

    const handleClickCloseChangeUsername = () => {
        setNewUsername(username)
        setOpenChangeUsername(false);
    };

    const handleClickOpenChangePhoneNumber = () => {
        setOpenChangePhoneNumber(true);
    };

    const handleClickCloseChangePhoneNumber = () => {
        setNewPhoneNumber(phoneNumber)
        setOpenChangePhoneNumber(false);
    };

    const handleClickOpenDeleteUser = () => {
        setOpenDeleteUser(true);
    };

    const handleClickCloseDeleteUser = () => {
        setOpenDeleteUser(false);
    };

    const usernameHandleChange = (event) => {
        setNewUsername(event.target.value);
    };

    const phoneNumberHandleChange = (event) => {
        setNewPhoneNumber(event.target.value);
    };

    const updateUsername = () => {
        updateName(newUsername);
        handleClickCloseChangeUsername();
    };

    const updatePhoneNumber = () => {
        updatePhone(newPhoneNumber);
        handleClickCloseChangePhoneNumber();
    };

    const deleteUser = event => {
        userRef.delete().then(() => {
            currentUser.delete().then(() => {
                history.push('/');
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });
            handleClickCloseDeleteUser();
        }).catch(function (error) {
            // An error happened.
        });
    };

    return (
        <Container fixed className={classes.contentWrapper}>
            <Box className={classes.informationBox}>
                <Box className={classes.boxHeader}>
                    <Link to="/" className={classes.backButton}>
                        <IconButton color="primary" component="span">
                            <ArrowBack />
                        </IconButton>
                    </Link>
                    <Box className={classes.informationBoxTitle}>
                        <Typography variant="h5">Informações de Perfil</Typography>
                        <Typography variant="overline">Informações básicas associadas ao seu perfil</Typography>
                    </Box>
                </Box>
                <Box className={classes.informatonSection}>
                    <Box>
                        <Typography>Nome</Typography>
                        <Typography>{username}</Typography>
                    </Box>
                    <Button onClick={handleClickOpenChangeUsername}>Alterar</Button>
                </Box>
                <Box className={classes.informatonSection}>
                    <Box>
                        <Typography>Telefone</Typography>
                        <Typography>{phoneNumber}</Typography>
                    </Box>
                    <Button onClick={handleClickOpenChangePhoneNumber}>Alterar</Button>
                </Box>
                <Box className={classes.deleteSection}>
                    <Button onClick={handleClickOpenDeleteUser} className={classes.deleteButton}>Deletar conta</Button>
                </Box>
            </Box>


            <Dialog open={openChangeUsername} onClose={handleClickCloseChangeUsername} aria-labelledby="form-dialog-change-Name">
                <DialogTitle id="form-dialog-title">Alterar Nome</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        onChange={usernameHandleChange}
                        margin="dense"
                        label="Novo Nome"
                        placeholder={username}
                        type="name"
                        fullWidth
                        variant="outlined"
                        value={newUsername ? newUsername : ''}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCloseChangeUsername} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={updateUsername} color="primary">
                        Atualizar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openChangePhoneNumber} onClose={handleClickCloseChangePhoneNumber} aria-labelledby="form-dialog-change-Name">
                <form onSubmit={updatePhoneNumber}>
                    <DialogTitle id="form-dialog-title">Alterar Número</DialogTitle>
                    <DialogContent>
                        <InputMask
                            mask="(99)99999 9999"
                            maskChar=" "
                            disabled={false}
                            onChange={phoneNumberHandleChange}
                        >
                            {() => <TextField
                                autoFocus
                                id="newPhoneNumber"
                                label="Telefone"
                                type="text"
                                autoComplete="current-number"
                                variant="outlined"
                                required={true}
                                className={classes.informationBoxInput}
                                helperText="Forma de contato em casos de perca de itens"
                            />}
                        </InputMask>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCloseChangePhoneNumber} color="primary">
                            Cancelar
                    </Button>
                        <Button onClick={updatePhoneNumber} color="primary">
                            Atualizar
                    </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openDeleteUser} onClose={handleClickCloseDeleteUser} aria-labelledby="form-dialog-change-Name">
                <DialogTitle id="form-dialog-title">Deletar Conta</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A deleção da conta resulta excluirá também todos os seus itens. Esta ação não poderá ser desfeita.
                    </DialogContentText>
                    <DialogContentText>
                        Deseja deletar sua conta?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCloseDeleteUser} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={deleteUser} className={classes.deleteButton} color="default">
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>


        </Container>
    );
}

export default Profile;