import React, { useEffect, useContext, useState } from "react";
import { Button, Box, Typography, Grid, Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withRouter, Redirect } from "react-router";
import { AuthContext } from "../../providers/Auth.js";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Notification from '../../components/Notification/index';
import { useStyles } from './styles.js';

const Login = ({ history }) => {
    const classes = useStyles();
    const { currentUser, logout, updateCurrentUser } = useContext(AuthContext);
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [openValidation, setOpenValidation] = useState(false);
    const [emailForReset, setEmailForReset] = useState('');
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });

    console.log("Login");

    const handleClickOpenResetPassword = () => {
        setOpenResetPassword(true);
    };

    const handleClickCloseResetPassword = () => {
        // setNewUsername(username)
        setOpenResetPassword(false);
    };

    const handleClickOpenValidation = () => {
        setOpenValidation(true)
    };

    const handleClickCloseValidation = () => {
        setOpenValidation(false)
    }

    const emailForResetHandleChange = (event) => {
        setEmailForReset(event.target.value);
    };

    useEffect(() => {
        // alert("o valor do currentUser é:"+ !!currentUser)
    }, [currentUser])

    const handleLogin = (event) => {
        event.preventDefault();
        const { email, password } = event.target.elements;
        try {
            firebase
                .auth()
                .signInWithEmailAndPassword(email.value, password.value)
                .then(authUser => {
                    if (authUser.user.emailVerified) {
                        //This will return true or false
                        updateCurrentUser(firebase.auth().currentUser);
                    } else {
                        handleClickOpenValidation();
                        logout();
                    }
                }).catch((error) => {
                    setNotify({
                        isOpen: true,
                        message: error.message,
                        type: "error"
                    })
                });
        } catch (error) {
            setNotify({
                isOpen: true,
                message: error.message,
                type: "error"
            })
        }
    }


    const resetPassword = event => {
        event.preventDefault();
        if (emailForReset !== '') {
            firebase.auth().sendPasswordResetEmail(emailForReset)
                .then((r) => {
                    setNotify({
                        isOpen: true,
                        message: "E-mail de recuperação enviado",
                        type: "success"
                    })
                    handleClickCloseResetPassword();
                })
                .catch((e) => {
                    switch (e.code) {
                        case 'auth/user-not-found':
                            setNotify({
                                isOpen: true,
                                message: "Usuário não cadastrado",
                                type: "error"
                            })
                            break;
                        case 'auth/invalid-email':
                            setNotify({
                                isOpen: true,
                                message: "E-mal inválido",
                                type: "error"
                            })
                            break;
                        case 'auth/user-disabled':
                            setNotify({
                                isOpen: true,
                                message: "Usuário desabilitado",
                                type: "error"
                            })
                            break;
                    }

                })
        }
        else {
            setNotify({
                isOpen: true,
                message: "Email não informado",
                type: "error"
            })
        }
    }

    if (!!currentUser && !!currentUser.emailVerified) {
        return <Redirect to="/" />
    }

    return (
        <>
            <Container maxWidth="lg" className={classes.generalConteiner}>
                <Grid container spacing={2} className={classes.sectionsWrapper} >
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.slogan}>
                        <Typography variant="h4" className={classes.applicationNameslogan}>Lostit</Typography>
                        <Typography variant="h2" className={classes.sloganText} >Seus itens sempre seus.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box className={classes.boxAlignment}>
                            <Box className={classes.boxContentDirection}>
                                <Box className={classes.informationBox}>
                                    <form onSubmit={handleLogin} className={classes.boxContentDirection}>
                                        <Box>
                                            <Typography variant="h5" className={classes.credentialTitle}>Entrar no Lostit</Typography>
                                            <Box className={classes.credentialHeader}>
                                                <Typography className={classes.newUser}>Novo usuário?</Typography>
                                                <Link to="/signUp">Cadastre-se</Link>
                                            </Box>
                                        </Box>
                                        <TextField
                                            id="email"
                                            label="E-mail"
                                            type="email"
                                            autoComplete="current-email"
                                            variant="outlined"
                                            className={classes.informationBoxInput}
                                        />
                                        <TextField
                                            id="password"
                                            label="Senha"
                                            type="password"
                                            autoComplete="current-password"
                                            variant="outlined"
                                            className={classes.informationBoxInput}
                                        />
                                        <Link className={classes.forgotPasswordLink} onClick={handleClickOpenResetPassword}>Esqueci minha senha</Link>
                                        <Button type="submit" variant="contained" color="primary" className={classes.mainButton}>Entrar</Button>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Dialog open={openResetPassword} onClose={handleClickCloseResetPassword} aria-labelledby="reset-password">
                    <DialogTitle id="reset-password">Recuperar senha</DialogTitle>
                    <form onSubmit={resetPassword}>
                        <DialogContent>
                            <DialogContentText>
                                Esqueceu a sua senha? Por favor, insira o seu e-mail. Você receberá uma senha provisória em email.
                        </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="E-mail"
                                type="email"
                                fullWidth
                                variant="outlined"
                                required={true}
                                onChange={emailForResetHandleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClickCloseResetPassword} color="secondary">Cancelar</Button>
                            <Button type="submit" color="primary">Recuperar senha</Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog open={openValidation} onClose={handleClickCloseValidation} aria-labelledby="validation-alert">
                    <DialogTitle id="validation-alert">Valide o seu email</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Precisamos validar o seu email. Acesse o seu e-mail para validar a sua conta
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCloseValidation} color="secondary">Ok</Button>
                    </DialogActions>
                </Dialog>
                <Notification
                    notify={notify}
                    setNotify={setNotify}
                />
            </Container>
        </>
    );
};

export default withRouter(Login);