import React, { useContext, useState } from 'react';
import firebase from '../../firebase';
import { Button, Typography } from '@material-ui/core';
import { useStyles } from './styles.js';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from "../../providers/Auth.js";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AppBar from '@material-ui/core/AppBar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const Navbar = () => {
    const { username, logOut } = useContext(AuthContext);
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" className={classes.navBarStructure}>
            <Link to="/" className={classes.logo}><Typography variant="h4" className={classes.logoText}>Lostit</Typography></Link>
            <div>
                <Button className={classes.profle} disableRipple onClick={handleMenu}>
                    <Typography style={{textTransform: 'none'}}>{username}</Typography>
                    <ArrowDropDownIcon fontSize="small" />
                </Button>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => { history.push('/profile'); handleClose()}}> Perfil </MenuItem>
                    <MenuItem onClick={() => logOut()}> Sair </MenuItem>
                </Menu>
            </div>
        </AppBar>
    );
};

export default Navbar;