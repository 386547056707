import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
    color: props => props.color,
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center"
  },

  returnButton: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px"
  },

  listElement: {
    height: "56px",
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    borderRadius: "16px",
    marginBottom: "16px",
    boxShadow: "rgba(112, 144, 176, 0.2) 4px 2px 16px 0px"
  },

  listElementIcon:{
    color: "#333333" 
  },

  hideCode:{
    display: "none"
  },

  sticker:{
    display: "none",
    padding: "8px",
    borderRadius: "8px",
    borderWidth: "2px",
    borderStyle: "dashed",
    width: "fit-content",
    backgroundColor: "#fff"
  },

  stickerDispostion: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
});

export { useStyles };