import React, { useCallback, useContext, useState } from 'react';
import { Button, Box, Typography, Grid, Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import firebase from '../../firebase';
import Notification from '../../components/Notification/index';
import { withRouter, Redirect } from 'react-router';
import { Link } from "react-router-dom";
import { AuthContext } from "../../providers/Auth.js";
import { useStyles } from './styles.js';


const SignUp = ({history}) => {

    const { currentUser } = useContext(AuthContext);
    const classes = useStyles();
    const [notify, setNotify] = useState({isOpen: false, message:'', type:''});

    console.log("SignUp");

    const handleSignUp = useCallback(async event => {
        event.preventDefault();
        const { email, password, userName, userPhone } = event.target.elements;
        try {
            await firebase
                .auth()
                .createUserWithEmailAndPassword(email.value, password.value).then(cred => {
                    const user = firebase.auth().currentUser;
                    user.sendEmailVerification().then(() => {
                        firebase.firestore().collection("users").doc(cred.user.uid).set({
                            name: userName.value,
                            phone: userPhone.value
                        })
                        setNotify({
                            isOpen: true,
                            message: "Usuário cadastrado. Verifique seu email para validação.",
                            type: "success"
                        })
                    })
                })
        } catch (error) {
            setNotify({
                isOpen: true,
                message: "Este email já está em uso por outra conta",
                type: "error"
            })
        }
    });

    if (!!currentUser && !!currentUser.emailVerified) {
        return <Redirect to="/" />
    }

    return (
        <>
            <Container maxWidth="lg" className={classes.generalConteiner}>
                <Grid container spacing={2} className={classes.sectionsWrapper}>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.slogan}>
                        <Typography variant="h4" className={classes.applicationNameslogan}>Lostit</Typography>
                        <Typography variant="h2" className={classes.sloganText}>Seus itens sempre seus.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box className={classes.boxAlignment}>
                            <Box className={classes.boxContentDirection}>
                                <Box className={classes.informationBox}>
                                    <form onSubmit={handleSignUp} className={classes.boxContentDirection}>
                                        <Box>
                                            <Typography variant="h5" className={classes.credentialTitle}>Cadastre-se no Lostit</Typography>
                                            <Box className={classes.credentialHeader}>
                                                <Typography className={classes.newUser}>Já possui uma conta?</Typography>
                                                <Link to="/login">Entre no Lostit</Link>
                                            </Box>
                                        </Box>
                                        <TextField
                                            id="email"
                                            label="Email"
                                            type="email"
                                            name="email"
                                            autoComplete="current-email"
                                            variant="outlined"
                                            required={true}
                                            className={classes.informationBoxInput}
                                        />

                                        <InputMask
                                            mask="(99)99999 9999"
                                            disabled={false}
                                            maskChar=" "
                                        >
                                            {() => <TextField
                                                id="userPhone"
                                                label="Telefone"
                                                type="tel"
                                                name="phone"
                                                autoComplete="current-number"
                                                variant="outlined"
                                                required={true}
                                                className={classes.informationBoxInput}
                                                helperText="Forma de contato em casos de perca de itens"
                                            />}
                                        </InputMask>

                                        <TextField
                                            id="userName"
                                            label="Nome"
                                            type="name"
                                            name="name"
                                            autoComplete="current-name"
                                            variant="outlined"
                                            required={true}
                                            className={classes.informationBoxInput}
                                        />
                                        <TextField
                                            id="password"
                                            label="Senha"
                                            type="password"
                                            name="password"
                                            autoComplete="current-password"
                                            variant="outlined"
                                            required={true}
                                            className={classes.informationBoxInput}
                                        />
                                        <Button type="submit" variant="contained" color="primary" className={classes.mainButton}>Cadastrar</Button>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container >
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    );
};

export default withRouter(SignUp);