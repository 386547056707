import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [userRef, setUserRef] = useState(null);
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!currentUser){
      // alert("não tenho currentUser")

      const unsubscribe = firebase.auth().onAuthStateChanged(() => {
        setCurrentUser(firebase.auth().currentUser);
        setIsAuthenticating(false)
      })
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
    else{
      // alert("já tenho currentUser")
    }
  }, []);

  useEffect(() => {
    if (!!currentUser) {
      // alert("currentUser foi mudado. Setando UserRef");

      setUserRef(firebase.firestore().collection("users").doc(currentUser.uid))
    }
  }, [currentUser]);

  useEffect(() => {
    if (!!userRef) {
      // alert("UserRef foi mudado. Coletando os dados do usuário (nome e telefone)");

      userRef.get().then((doc) => {
        if (doc.exists) {
          setUsername(doc.data().name);
          setPhoneNumber(doc.data().phone);
        } else {
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      })
    }
  }, [userRef])

  if (isAuthenticating) {
    return <Box style={{ width: "100vw", height: "100vh", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  }

  const updateCurrentUser = (user) => {
      setCurrentUser(user)
  }

  const login = (email, password) => {
    return firebase.auth.signInWithEmailAndPassword(email, password);
  };

  const logOut = () => {
    //auth signout and clear current user
    firebase.auth().signOut();
    setCurrentUser(null);
    setUserRef(null);
}

  const resetPassword = email => {
    return firebase.auth.sendPasswordResetEmail(email);
  };

  const updatePassword = password => {
    return currentUser.updatePassword(password);
  };

  const updateName = (newValue) => {
    userRef.update({
      name: newValue
    }).then(() => {
      setUsername(newValue);
    }).catch((error) => {
      console.error("Error writing document: ", error);
    });
  };

  const updatePhone = (newValue) => {
    userRef.update({
      phone: newValue
    }).then(() => {
      setPhoneNumber(newValue);
    }).catch((error) => {
      console.error("Error writing document: ", error);
    });
  };

  const values = {
    currentUser,
    isAuthenticating,
    userRef,
    username,
    phoneNumber,
    login,
    logOut,
    resetPassword,
    updatePassword,
    updateCurrentUser,
    updateName,
    updatePhone
  }

  return (
    <AuthContext.Provider value={values}>
      {!isAuthenticating && children}
    </AuthContext.Provider>
  );
};