import React, { useState, useContext, useEffect } from 'react'
import { Container, TextField, Button, Box, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../providers/Auth.js";
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './styles.js'

const EditItem = ({ history }) => {
    const classes = useStyles();
    const { itemId } = useParams();
    const { userRef } = useContext(AuthContext);
    const [itemName, setItemName] = useState();
    const [itemDescription, setItemDescription] = useState();
    const [btnDisabled, setButtonDisabled] = useState(false);


    useEffect(() => {
        userRef.collection("items").doc(itemId).get().then(doc => { setItemName(doc.data().name) });
        userRef.collection("items").doc(itemId).get().then(doc => { setItemDescription(doc.data().description) });
    }, [])


    const uploadItem = event => {
        setButtonDisabled(true);
        event.preventDefault();
        userRef.collection("items").doc(itemId).update({
            name: itemName,
            description: itemDescription
        })
            .then(() => {
                history.push('/');
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
    }

    const handleDelete = event => {
        event.preventDefault();
        event.stopPropagation();
        userRef.collection("items").doc(itemId).delete().then(() => {
            history.push('/');
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    };

    const itemNameHandleChange = (event) => {
        setItemName(event.target.value);
    };
    const itemDescriptionHandleChange = (event) => {
        setItemDescription(event.target.value);
    };

    return (
        <Container fixed className={classes.contentWrapper}>
            <Box className={classes.informationBox}>
                <Box className={classes.boxHeader}>
                    <Link to="/" className={classes.backButton}>
                        <IconButton color="primary" component="span">
                            <ArrowBack />
                        </IconButton>
                    </Link>
                    <Box className={classes.informationBoxTitle}>
                        <Typography variant="h5">Editar Item</Typography>
                        <Typography variant="caption">Altere nome e descrição do seu item</Typography>
                    </Box>
                </Box>


                <form className={classes.informationBoxForm} onSubmit={uploadItem} >
                    <TextField
                        onChange={itemNameHandleChange}
                        className={classes.informationBoxInput}
                        value={itemName ? itemName : ''}
                        label="Nome do item"
                        size="large"
                        variant="outlined"
                        color="primary"
                    />
                    <TextField
                        onChange={itemDescriptionHandleChange}
                        className={classes.informationBoxInput}
                        value={itemDescription ? itemDescription : ''}
                        label="Descrição"
                        multiline={true}
                        variant="outlined"
                        rows={3}
                    />
                    <Button disabled={btnDisabled} type="submit" variant="contained" color="primary" className={classes.informationBoxButton}>Atualizar</Button>
                    <Button onClick={handleDelete} className={classes.deleteButton}>Deletar</Button>
                </form>
            </Box>
        </Container>
    );
}

export default EditItem;