import React, { useState, useEffect, useContext } from "react";
import { Button, Typography, Container, Box } from '@material-ui/core';
import { Link } from "react-router-dom";
import ItemCard from '../../components/ItemCard/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthContext } from "../../providers/Auth.js";
import { useStyles } from './styles.js'

const Home = ({ history }) => {
    const classes = useStyles();
    const { userRef } = useContext(AuthContext);
    const userItems = userRef.collection("items");
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    console.log("Home");
    
    function getItems() {
        setIsLoading(true);
        userItems.onSnapshot((querySnapshop) => {
            const items = [];
            querySnapshop.forEach((doc) => {
                items.push(doc);
            });
            setItems(items);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getItems();
    }, [])

    if (isLoading) {
        return <Box className={classes.loadingWrapper} > <CircularProgress /> </Box>
    }

    return (
        <>
            <Container fixed>
                <Box className={classes.headerSection}>
                    <Box className={classes.header}>
                        <Typography variant="h4"> Seus Itens </Typography>
                        <Link to="/newItem" className={classes.removeLinkStyle}>
                            <Button variant="contained" size="large" color="primary" className={classes.mainButton}>Novo item</Button>
                        </Link>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2">
                            {items ? `Você possui ${items.length} ${(items.length > 1) ? "itens cadastrados" : "item cadastrado"}` :
                                "você ainda não possui itens cadastrados"}
                        </Typography>
                    </Box>
                </Box>

                {items.map((item) => (
                    <Link to={`item/${userRef.id}/${item.id}`} className={classes.removeLinkStyle}>
                        <ItemCard name={item.data().name} user={userRef} itemId={item.id} />
                    </Link>
                ))}
            </Container>
        </>
    );
}

export default Home;