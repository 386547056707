import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
    color: props => props.color,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "104px"
  },

  generalConteiner: {
    display: "flex",
    height: "100%"
  },

  sectionsWrapper: {
    display: "flex",
    alignSelf: "center"
  },

  slogan:{
    display: "flex",
    alignSelf: "center",
    flexDirection: "column"
  },

  credentialTitle:{
    marginBottom: "8px",
    fontWeight: "bold"
  },

  newUser:{
    marginBottom: "16px",
    marginRight: "8px"
  },

  credentialHeader:{
    display: "flex"
  },

  informationBox:{
      width: "320px",
      padding: "24px",
      marginTop: "16px",
      backgroundColor: "#ffffff",
      border: " 1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "8px"
  },

  informationBoxInput:{
    marginBottom: '16px'
  },

  boxAlignment:{
    display: "flex",
    justifyContent: "center"
  },

  boxContentDirection:{
    display: "flex",
    flexDirection: "column"
  },

  applicationNameslogan:{
    color: "#00BD08", fontWeight: "bold"
  },

  sloganText:{
    color: "#333333", fontWeight: "bold"
  },

  mainButton:{
    backgroundColor: "#00BD08",
    '&:hover': {
      backgroundColor: "#00BD08"
    }
  },

  forgotPasswordLink:{
    marginBottom: "16px"
  }


});

export { useStyles };