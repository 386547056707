import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
    color: props => props.color,
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center"
  },

  returnButton: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px"
  },

  informationBox: {
    width: "320px",
    padding: "24px",
    marginTop: "24px",
    backgroundColor: "#ffffff",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px"
  },

  informationBoxTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBottom: "24px"
  },

  informationBoxForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  informationBoxInput: {
    width: 320,
    marginBottom: "16px"
  },

  mainButton:{
    backgroundColor: "#00BD08",
    '&:hover': {
      backgroundColor: "#00BD08"
    }
  },


});

export { useStyles };