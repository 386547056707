import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
    color: props => props.color,
  },

  contentWrapper: {
    justifyContent: "center"
  },
  
  backButton: {
    marginRight: "16px"
  },

  informationBox: {
    padding: "24px",
    marginTop: "24px",
    backgroundColor: "#ffffff",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px"
  },

  boxHeader: {
    display: "flex"
  },

  informationBoxTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBottom: "24px",
    flexDirection: "column"
  },

  informationBoxForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  informationBoxInput: {
    // width: 320,
    marginBottom: "16px"
  },

  informationBoxButton: {
    backgroundColor: "#00BD08",
    marginBottom: "16px",
    '&:hover': {
      backgroundColor: "#00BD08"
    }
  },

  deleteButton: {
    '&:hover': {
      color: 'white',
      backgroundColor: "red"
    }
  },


});

export { useStyles };