import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  mainSection:{
    width: "100vw",
    height: "calc(100vh - 100px)",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center'
  },

  lowerSection:{
    width: "100vw",
    heght: "100px",
    display: "flex",
    justifyContent: "center"
  }
});

export { useStyles };